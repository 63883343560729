<template>
    <div>

        <v-btn color="green">New Message</v-btn>

        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            solo
            hide-details
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="messages"
            :loading="loading"
            :options.sync="options"
            :search="search"
            :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
            :server-items-length="total"
            sort-by="sent"
            sort-desc
            must-sort
        >
            <template v-slot:item="props">
                <tr :class="props.item.read ? 'font-weight-normal' : 'font-weight-bold'">
                    <td>
                        <div v-if="props.item.from && props.item.from_username">
                            <router-link :to="'/user/' + props.item.from_username" class="no-underline color-inherit">
                                <UserAvatarList
                                    :user="{ID: props.item.from, Username: props.item.from_username, Callsign: props.item.from_callsign}"
                                    xsmall
                                />
                            </router-link>

                            <router-link :to="'/user/' + props.item.from_username" class="no-underline color-inherit">
                                {{ props.item.from_username }}
                            </router-link>&nbsp;
                            <small>
                                <router-link :to="'/license/' + props.item.from_callsign"
                                             class="no-underline color-inherit">
                                    {{ props.item.from_callsign }}
                                </router-link>
                            </small>
                        </div>
                    </td>
                    <td>{{ formatDate(props.item.sent) }}</td>
                    <td class="truncate">{{ props.item.subject }}</td>
                    <td class="truncate">{{ props.item.body }}</td>
                    <td class="text-right">
                        <!--<v-btn icon dark class="mr-2 success" title="Mark as Read"><v-icon>mdi-check</v-icon></v-btn>-->
                        <v-btn icon dark class="mr-2 info" title="Reply">
                            <v-icon>mdi-reply</v-icon>
                        </v-btn>
                        <!--<v-btn icon dark class="mr-2 info" title="Forward"><v-icon>mdi-share</v-icon></v-btn>-->
                        <v-btn icon dark class="mr-2 error" title="Delete">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<style>
    .truncate {
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<script>
    import moment from 'moment';
    import UserAvatarList from '@/components/UserAvatarList.vue';
    import axios from "axios";
    import config from "@/config";

    export default {
        name: 'Inbox',

        props: ['user'],

        components: {
            UserAvatarList
        },

        data: () => ({
            search: null,
            loading: false,
            options: {},
            headers: [
                {text: 'From', value: 'from_username', width: 300, sortable: true},
                {text: 'Sent', value: 'sent', width: 200},
                {text: 'Subject', value: 'subject', sortable: false, width: 300},
                {text: 'Message', value: 'body', sortable: false},
                {text: '', value: 'actions', sortable: false, width: 175},
            ],
            total: 0,
            messages: []
        }),

        watch: {
            options: {
                async handler() {
                    await this.fetchMessages();
                },
                deep: true,
            },
        },

        methods: {
            formatDate(date) {
                return moment(date).format('MM/DD/YYYY h:mm a');
            },

            async fetchMessages() {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    this.loading = true;
                    const offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    const response = await axios.get(`${config.API_LOCATION}/messages?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`, {
                        headers: axiosHeaders
                    });
                    this.loading = false;

                    this.total = response.data.info.total;
                    this.messages = response.data.messages;
                } catch (err) {
                    console.error(err);
                }
            }
        },

        async mounted() {
            await this.fetchMessages();
        }
    }
</script>
